<template>
  <span style="font-size: 11pt; font-weight: 500;">Cuotas por curso</span>
  <div class="p-list-group p-mb-1 p-mt-1">
    <div v-for="item in cuotas_curso" :key="item"
      class="p-list-group-item p-px-2 p-py-1 p-d-flex p-jc-between p-ai-center">
      <div class="p-d-flex p-jc-start p-ai-start p-flex-column">
        <span style="font-weight:500;">{{ item.descripcion }}</span>
        <small :style="item.recargo == 1 && parametros.aplica ? 'color:red !important;' : ''" class="text-muted">
          <span>{{ item.fecha_limite }}</span>
          <span v-if="item.recargo == 1 && parametros.aplica">&nbsp;(recargo)</span>
        </small>
      </div>
      <div class="p-d-flex p-flex-column">
        <span style="font-size:12pt; font-weight:500;">{{ $format.usd(precio(item.precio)) }}</span>
        <small v-if="item.recargo == 1 && parametros.aplica" style="color:red;">+{{ $format.usd(item.precio * (parametros.porcentaje / 100) )}}</small>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'DetalleCuotas',
  props: {
    parametros: {
      type: Object
    },
    cuotas: [],
    curso: {
      type: Number,
    },
    precio_cuota: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    precio(precio) {
      console.log(this.precio_cuota);
      if (this.precio_cuota == 0) return precio;
      return this.precio_cuota;
    },
  },
  computed: {
    cuotas_curso: function () {
      return this.cuotas.filter(x => x.curso == this.curso);
    },
  }
}
</script>
