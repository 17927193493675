<template>
  <Toast />
  <div class="w-full">
    <Message class="p-mt-0" :closable="false">Actualiza tus datos para poder empezar a recibir tu factura electrónica.</Message>
    <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
      <div class="p-col-3">
        <label for="documento">*Identificación:</label>
      </div>
      <div class="p-col-9">
        <div class="p-col-12 p-w-0 p-p-0 p-m-0 p-d-flex">
          <Dropdown 
            v-model="formulario.tipo_documento" 
            :loading="tipo_doc_isloading" 
            :options="tipo_documento_list" 
            v-bind:class="{'p-invalid' : !isvalid.codpais}" 
            optionLabel="nombre" 
            optionValue="codigo" />
          <InputText 
            id="documento" 
            style="width: 100%;"
            v-bind:class="{'p-invalid' : !isvalid.documento}" 
            v-model="formulario.documento" 
            autocomplete="off" 
            placeholder="Escriba el documento" />
        </div>
        <small class="text-danger" v-if="!isvalid.documento">Ingrese un documento válido de acuerdo al tipo seleccionado</small>
      </div>
    </div>
    <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
        <div class="p-col-3">
          <label for="txt_correo">*Correo:</label>
        </div>
        <div class="p-col-9">
          <InputText 
            style="width: 100%;"
            id="txt_correo" 
            v-bind:class="{ 'p-invalid' : !isvalid.correo }" 
            v-model="formulario.correo" 
            autocomplete="off" 
            placeholder="Correo electrónico" />
          <small class="text-danger" v-bind:class="{ 'p-hidden' : email_is_valid }">Debe ingresar un correo válido.</small>
          <small class="text-danger" v-bind:class="{ 'p-hidden' : isvalid.correo }">Debe ingresar el correo.</small>
          <small class="text-danger" v-bind:class="{ 'p-hidden' : correo_repetido }">Este correo ya se encuentra registrado.</small>
        </div>
    </div>
    <div class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
      <div class="p-col-3">
        <label>*Contacto:</label>
      </div>
      <div class="p-col-9">
        <div class="p-col-12 p-w-0 p-p-0 p-m-0 p-d-flex">
          <InputText 
            id="codigo_pais" 
            style="width: 80px;"
            v-model="formulario.codpais"
            v-bind:class="{ 'p-invalid' : !isvalid.codpais }" 
            maxlength="4"
            placeholder="Ej. +503"/>
          <InputText 
            style="width: 100%;"
            id="telefono" 
            v-model="formulario.telefono" 
            v-bind:class="{'p-invalid' : !isvalid.telefono}" 
            autocomplete="off" 
            placeholder="Su número de teléfono" />
        </div>
        <small class="text-danger" v-if="!isvalid.codpais">Ingrese el código Ej. +123</small>
        <br v-if="!isvalid.codpais && !isvalid.telefono">
        <small class="text-danger" v-if="!isvalid.telefono">Ingrese el número sin guiones</small>
      </div>
    </div>
    <!-- formulario.extranjero -->
    <div v-if="!formulario.extranjero" class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
        <div class="p-col-3">
          <label for="direccion">*Dirección:</label>
        </div>
        <div class="p-col-9">
          <InputText 
            style="width: 100%;"
            id="direccion" 
            v-bind:class="{ 'p-invalid' : !isvalid.direccion }" 
            v-model="formulario.direccion" 
            autocomplete="off" 
            placeholder="Dirección de residencia" />
        </div>
    </div>
    <div v-if="!formulario.extranjero" class="p-mb-2 p-grid p-d-flex p-jc-between p-ai-center p-flex-wrap">
      <div class="p-col-3">
        <label for="departamento"><i v-if="!formulario.es_extranjero">*</i>Departamento:</label>
      </div>
      <div class="p-col-9">
        <Dropdown 
          style="width: 100%;"
          v-bind:class="{ 'p-invalid' : !isvalid.departamento }" 
          placeholder="Seleccione" 
          v-model="formulario.departamento" 
          id="departamento" 
          @change="obtener_municipios" 
          :options="lista_departamentos" 
          optionLabel="departamento" 
          optionValue="codigo" 
          :loading="departamento_is_loading"/>
      </div>
    </div>
    <div v-if="!formulario.extranjero" class="p-mb-2 p-grid p-d-flex p-jc p-ai-center p-flex-wrap">
      <div class="p-col-3">
        <label for="municipio"><i v-if="!formulario.es_extranjero">*</i>Municipio:</label>
      </div>
      <div class="w-full p-col-9">
        <Dropdown 
          style="width: 100%;"
          v-bind:class="{ 'p-invalid' : !isvalid.municipio }" 
          placeholder="Seleccione" 
          v-model="formulario.municipio" 
          id="municipio" 
          :options="lista_municipios" 
          optionLabel="municipio" 
          optionValue="codigo" 
          :loading="municipio_is_loading" 
          emptyMessage="Seleccione departamento"/>
      </div>
    </div>
    <div>
      <Button class="p-button-ugb" @click="actualizar_datos" label="ACTUALIZAR DATOS"></Button>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ActualizacionDatos',
    data(){
      return {
        lista_departamentos: [],
        lista_municipios: [],
        tipo_documento_list : [],
        tipo_doc_isloading : true,
        actualizar_is_loading : false,
        departamento_is_loading : true,
        municipio_is_loading : false,
        correo_repetido : true,
        datos_cliente : [],
        is_loading : true,
        formulario : {
          tipo_documento : 1,
          documento : '',
          correo : '',
          codpais : '+503',
          telefono : '',
          direccion : '',
          departamento : 0,
          municipio : 0,
          extranjero : false,
        },
        isvalid : {
          documento : true,
          codpais : true,
          telefono : true,
          direccion : true,
          departamento : true,
          municipio : true,
          correo : true,
        },
      }
    },
    methods : {
      async actualizar_datos(){
        const regex = new RegExp(this.formato);

        this.isvalid.correo = this.formulario.correo.length == 0 ? false : true;
        this.isvalid.documento = this.formulario.documento.length == 0 || !regex.test(this.formulario.documento) ? false : true;
        if( !this.formulario.extranjero ){
          this.isvalid.direccion = this.formulario.direccion.length == 0 ? false : true
          this.isvalid.codpais = this.formulario.codpais.length == 0 || !/^\+\d{1,3}$/.test(this.formulario.codpais) ? false : true
          this.isvalid.telefono = this.formulario.telefono.length == 0 || !/^\d{8,11}$/.test(this.formulario.telefono) ? false : true;
          this.isvalid.departamento = this.formulario.departamento == 0 && !this.formulario.es_extranjero ? false : true
          this.isvalid.municipio = this.formulario.municipio == 0 && !this.formulario.es_extranjero ? false : true
        }

        if(!this.email_is_valid) return false;

        let v = Object.values(this.isvalid).indexOf(false) >= 0;
        if(v) return false;

        this.actualizar_is_loading = true;
        await this.axios.post("/Cursos/ActualizarCliente", this.formulario)
          .then(r => { 
            var response = r.data;
            
            this.$toast.add(
              {
                severity: response.estado == 1 ? 'success' : 'error' , 
                summary: 'Aviso', 
                detail: response.estado == 1 ? 'Información Actualizada' : response.mensaje, 
                life: 3000
              }
            );
            this.$emit('closeUpdateDataModal', 1);
          })
          .catch(() => { })
          .then(() => { this.actualizar_is_loading = false; });
      },
      async obtener_departamentos(){
        this.departamento_is_loading = true
        await this.axios.get("/Registro")
              .then(r => { this.lista_departamentos = r.data })
              .catch(() => { })
        .then(() => { this.departamento_is_loading = false })
      },
      async obtener_municipios(){
        this.municipio_is_loading = true
        await this.axios.get("/Registro/Municipios/"+this.formulario.departamento)
          .then(r => { this.lista_municipios = r.data })
          .catch(() => { })
          .then(() => { this.municipio_is_loading = false })
      },
      async obtener_tipoDocumentos(){
        this.tipo_doc_isloading = true
        await this.axios.get("/Registro/TipoDocumentos")
          .then(r => { this.tipo_documento_list = r.data })
          .catch(() => { })
          .then(() => { this.tipo_doc_isloading = false })
      },
      async traer_datos_cliente(){
        this.tipo_doc_isloading = true
        await this.axios.get("/Cursos/TraerDatosCliente")
          .then(r => { 
            console.log(r.data);
            var data = r.data[0];
            this.formulario.tipo_documento = data.codtpd;
            this.formulario.documento = data.documento;
            this.formulario.correo = data.correo;
            this.formulario.codpais = data.telefono.split("-")[0].includes("+") ? data.telefono.split("-")[0] : '+503';
            this.formulario.telefono = data.telefono.split("-")[0].includes("+") ? data.telefono.split("-")[1] : data.telefono;
            console.log(data.telefono.split("-")[0].includes("+"));
            this.formulario.direccion = data.direccion;
            this.formulario.departamento = data.coddep;
            this.formulario.municipio = data.codmun;
            this.formulario.extranjero = data.extranjero;
            this.obtener_municipios();
          })
          .catch(() => { })
          .then(() => { this.is_loading = false })
      },
    },
    computed : {
      formato : function(){
        return this.tipo_documento_list.length > 0 && this.tipo_documento_list.filter( x => x.codigo == this.formulario.tipo_documento )[0].formato;
      },
      email_is_valid : function(){
        //eslint-disable-next-line
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        return this.formulario.correo == '' ? true : re.test(this.formulario.correo)
      },
    },
    created : function(){
      this.obtener_departamentos();
      this.obtener_tipoDocumentos();
      this.traer_datos_cliente();
    },
  }
</script>
