<template>
  <div v-if="vista == 'cartas'">
      <div class="p-mx-2 p-card-item" style="width:300px !important; max-width:300px !important; height: 100% !important;">
          <div class="p-col p-d-flex p-flex-column p-elevation p-p-0" style="border:1px solid #e4e4e4; border-radius:5px;">
              <!-- <div class="p-p-0 image" :style="image(item.imagen)"></div> -->
              <Image :src="item.imagen" style="width: 298px !important;" width="298" height="200" class="p-p-0 image" alt="Image" preview />
              <div class="p-d-flex p-flex-column p-ai-center p-px-3 p-py-2">
                  <div class="p-px-1" style="flex-grow: 3; cursor:pointer;" @click="mostrar_detalles(item.codigo)">

                      <div class="p-d-flex p-flex-column p-ai-start p-jc-start p-mb-1 p-col-12 p-p-0">
                          <div class="p-d-flex p-jc-between p-ai-baseline p-col-12 p-p-0 p-mb-1">
                              <small class="text-muted">{{$format.cut(item.facultad, 35)}}</small>
                              <Tag value="Plataforma"></Tag>
                          </div>
                          <small class="p-ml-2" style="background:#263680; color:white; padding:2px 6px; border-radius: 5px;" v-if="item.recomendado == 1">Recomendado</small>
                      </div>
                      <p :title="item.nombre" style="font-size:11pt; font-weight:500; color:#424242; height:45px !important;" class="p-mb-0">{{$format.cut(item.nombre, 50)}}</p>
                      <p style="height:90px !important; color:#424242;" class="p-mb-0">{{$format.cut(item.descripcion, 145)}}</p>
                      <div class="p-d-flex p-jc-between p-ai-center p-mb-2" style="height:60px !important;">
                          <div class="p-d-flex p-flex-column p-jc-start p-my-2" style="font-size:11pt;">
                              <small style='margin:1px 0px;' class="text-muted p-text-capitalize">{{item.regional.toLowerCase()}}</small>
                              <small style='margin:1px 0px;' class="text-muted p-text-capitalize">Virtual</small>
                              <small style='margin:1px 0px;' class="text-muted p-text-uppercase">{{item.ciclo}}</small>
                          </div>
                          <div class="p-d-flex p-flex-column p-jc-start">
                              <span style="font-size:16pt;" class="p-my-0">{{$format.usd(item.precio)}}</span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="p-fluid p-px-3 p-pb-3">
                  <Button v-if="item.inscrito < 0" class="p-button-ugb" icon="" label="INSCRIBIRME"  @click="detalle_pago(item.codigo)"></Button>
                  <Button v-else class="p-button-ugb" icon="pi pi-check-circle" label="INSCRITO" disabled="disabled"></Button>
                  <small class="p-text-secondary">Última fecha de pago {{item.pago_final}}</small>
              </div>
          </div>
      </div>
  </div>
  
  <div v-else class="lista p-grid p-mx-2 p-d-flex p-jc-start p-ai-start p-elevation" style="border: 1px solid rgb(228, 228, 228);  border-radius:4px;">
      <!-- <div class="lista-imagen image-land" :style="image(item.imagen)"></div> -->
      <Image :src="item.imagen" class="lista-imagen image-land" style="width: 298px;" width="298" height="200" alt="Image" preview />
      <div class="lista-cuerpo p-col p-pl-4 p-d-flex p-flex-column p-ai-center p-jc-between" style="height:200px;">
          <div style="width:100% !important;" class="lista-info pointer" @click="mostrar_detalles(item.codigo)">
              <div class="p-d-flex p-ai-center p-jc-start categoria">
                  <Tag value="Plataforma"></Tag>
                  <small class="text-muted">{{$format.cut(item.facultad, 35)}}</small>
                  <small class="p-ml-2" style="background:#263680; color:white; padding:2px 6px; border-radius: 5px;" v-if="item.recomendado == 1">Recomendado</small>
              </div>
              <p style="font-size:11pt; font-weight:500; color:#424242;" class="lista-nombre p-mb-0">{{$format.cut(item.nombre, 50)}}</p>
              <p style="font-size:10pt; color:#424242;" class="lista-descripcion p-mb-0">{{$format.cut(item.descripcion, 145)}}</p>
              <div class="p-d-flex p-jc-between p-ai-center p-mb-1" style="height:60px !important; width:100%;">
                  <div class="p-d-flex p-flex-column p-jc-around p-my-2" style="font-size:11pt;">
                      <small style='margin:1px 0px;' class="text-muted p-text-capitalize">{{item.regional.toLowerCase()}}</small>
                      <small style='margin:1px 0px;' class="text-muted p-text-capitalize">Virtual</small>
                      <small style='margin:1px 0px;' class="text-muted p-text-uppercase">{{item.ciclo}}</small>
                  </div>
                  <div class="p-d-flex p-flex-column p-jc-start p-px-6 lista-precio">
                      <span style="font-size:16pt;" class="p-my-0">{{$format.usd(item.precio)}}</span>
                  </div>
              </div>
          </div>
          <div class="lista-botones p-col-12 p-d-flex p-jc-between p-ai-center p-p-0">
              <small class="lista-fecha-pago p-text-secondary">Última fecha de pago {{item.pago_final}}</small>
              <Button v-if="item.inscrito < 0" class="lista-boton-pago p-button-ugb p-button-sm" label="INSCRIBIRME"  @click="detalle_pago(item.codigo)"></Button>
              <Button v-else class="lista-boton-pago p-button-ugb p-button-sm" icon="pi pi-check-circle" label="INSCRITO" disabled="disabled"></Button>
          </div>
      </div>
  </div>
  
  
</template>
<script>
  export default {
      name: 'ElementoListaCardRuta',
      props: {
          item : {},
          vista : {
              type : String
          },
      },
      methods : {
          mostrar_detalles : function(codigo){
              this.$emit('mostrar_detalles', codigo);
          },
          detalle_pago : function(codigo){
              this.$emit('detalle_pago', codigo);
          },
          image(url){
              return "background-image:url('"+url+"')"
          },
      },
      computed : {},
      mounted (){
      },
  }
</script>