<template>
  <div class="p-d-flex p-flex-column p-pt-2">
    <div class="p-list-group p-mb-2">
      <div class="p-list-group-item p-d-flex p-jc-between p-ai-center" v-for="item in cuotas" :key="item">
        <div class="p-d-flex">
          <div class="p-field-checkbox p-mb-0 p-mr-2">
            <Checkbox 
              :id="item.codigo" 
              name="cuotas" 
              @change="actualizar_cuotas(item.codigo)" 
              :value="item.codigo"
              :disabled="item.codigo === cuota_defecto" 
              v-model="lista_cuotas" />
          </div>
          <div class="p-d-flex p-flex-column p-jc-start p-ai-start">
            <label>{{ item.descripcion }}</label>
            <small class="text-muted" :style="item.recargo == 1 ? 'color:red !important;' : ''">
              <span>{{ item.fecha_limite }}</span>
              <span v-if="item.recargo == 1">&nbsp;(recargo)</span>
            </small>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-jc-end p-ai-end">
          <span style="font-size:12pt; font-weight:500;">{{ $format.usd(item.precio) }}</span>
          <small v-if="item.recargo == 1" style="color:red;">+{{ $format.usd(item.precio * (15 / 100)) }}</small>
        </div>
      </div>
    </div>
    <div class="p-d-flex p-jc-start p-ai-center">
      <small class="text-muted">Nota. La primera cuota es obligatoria.</small>
    </div>
  </div>
  <Divider class="p-mt-3" />
  <div class="p-d-flex p-jc-between p-ai-center">
    <span style="font-size:13pt; ">Total: <span
        style="font-weight:500;">{{ $format.usd(this.total_cuotas) }}</span></span>
    <Button label="ACEPTAR" @click="close_modal" class="p-button-ugb"></Button>
  </div>
  <!-- <pre>{{ cuotas }}</pre> -->
</template>

<script>
export default {
  name: 'AgregarCuota',
  props: {
    codigo_participante: {
      type: Number,
      default: 0,
    },
    codigo_curso: {
      type: Number,
      default: 0,
    },
    cuotas: [],
    precio_cuota: {
      type: Number,
      default: 0,
    },
    cuota_defecto: {
      type: Number,
      default: 0,
    },
    cuotas_pago: []
  },
  data: function () {
    return {
      lista_cuotas: [],
    }
  },
  methods: {
    actualizar_cuotas(id) {
      let e = !document.getElementById(id).checked;
      let ni = this.cuotas.findIndex(x => x.codigo == id);
      let n = this.cuotas[ni].n;
      this.lista_cuotas = [];
      for (var i = 1; i <= this.cuotas.length; i++) {
        let index = this.cuotas.findIndex(x => x.n == i);
        let numero = this.cuotas[index].n;
        let codigo = this.cuotas[index].codigo;
        if (e) {
          if (n >= numero) this.lista_cuotas.push(codigo);
        } else {
          if (n > numero) this.lista_cuotas.push(codigo);
        }
      }
      this.$emit('actualizar_cuotas', this.codigo_participante, this.lista_cuotas);
    },
    close_modal() {
      this.$emit('close_modal', true);
    },
  },
  watch: {
    lista_cuotas: function (n, o) {
      this.old_lista_cuotas = o.slice();
    }
  },
  computed: {
    cuotas_: function () {
      let d = [];
      this.cuotas_pago.forEach(x => {
        if (x.participante == this.codigo_participante) {
          d.push(x.cuota);
        }
      });
      return d;
    },
    total_cuotas: function () {
      var total = 0;
      total = this.precio_cuota * this.lista_cuotas.length;
      this.cuotas.forEach(x => {
        if (this.lista_cuotas.includes(x.codigo) && x.recargo == 1) {
          total += (this.precio_cuota * (15 / 100))
        }
      })
      return total;
    },
    new_lista_cuotas: function () {
      return this.lista_cuotas;
    }
  },
  created: function () {
    this.lista_cuotas = this.cuotas_;
  },
}
</script>