<template>
  <div class="p-px-2 p-py-2">
    <h4>Cómo agregar participantes</h4>
    <p style="text-align:justify;">Es posible inscribir varias personas a la vez a un mismo curso.</p>
    <p style="text-align:justify;">
      Para realizar esta acción es necesario que el o los participantes se hayan registrado previamente en la plataforma, al estar registrados se pueden agregar a la lista de participantes mediante la opción de <i>"Agregar Participantes"</i> y seleccionando la opción de <i>"Buscar"</i> ingresando su carnet o correo, se debe hacer clic al botón de busqueda y al cargar los datos seleccionar el participante para incluirlo.
    </p>
    <p>Si un participante ya se ha inscrito previamente en el curso o evento este no se encontrarán resultados al buscarlo, al igual que si el participante ya se encuentra en la lista no se permitirá duplicarlo.
    </p>

    <h4>Promociones grupales</h4>
    <p style="text-align:justify;">
      En ocasiones pueden haber ofertas al inscribir varios participantes a la vez a un curso, estas promociones se pueden consultar en el apartado de información de precios del curso.
    </p>
    <h4>Consideraciones</h4>
    <p style="text-align:justify;">
      Es importante considerar que al registrar varias personas a la vez 
      se realizará un pago único de la totalidad del curso o evento por la cantidad de participantes.
    </p>
    <div class="p-col p-p-0">
      <Button label="Aceptar" icon="pi pi-check" @click="CerrarModal" class="p-px-0 p-button-text p-button-secondary"></Button>
    </div>
  </div>
</template>

<script>
export default{
  name : 'InformacionParticipantes',
  methods : {
    CerrarModal(){
      this.$emit('CerrarModal')
    },
  }
}
</script>
