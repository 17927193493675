<template>
    <div class="p-d-flex p-flex-column">
        <span style="font-size:11pt; font-weight:500;" class="p-mb-1">Precios por inscripción en grupo</span>
        <div class="p-list-group p-mb-0">
            <div v-for="item in precios" :key="item" class="p-list-group-item p-px-2 p-d-flex p-jc-between">
                <span v-if="item.limite">De {{item.desde}} a {{item.hasta}} participantes</span>
                <span v-else>Desde {{item.desde}} participantes</span>
                <span class="p-text-bold">{{$format.usd(item.precio)}}</span>
            </div>
        </div>
        <small class="p-mt-2"><b>Nota.</b> El precio se aplicará por cada participante.</small>
    </div>
</template>

<script>
export default {
    name: 'PreciosPreferenciales',
    props : {
        precios : [],
    },
}
</script>