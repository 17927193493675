<template>
    <div class="p-d-flex p-flex-column p-ai-start p-jc-between">
        <div class="p-col p-px-0" style="width:100% !important;">
            <form action="" @submit.prevent="obtener_participantes">
                <div class="p-d-flex p-ai-center p-jc-center">
                    <InputText 
                        ref="parametro" 
                        :placeholder="placeholder_text" 
                        :class="{'p-invalid' : !parametro_is_valid}" 
                        @keydown="parametro_is_valid = true" 
                        v-model="parametro_participante" 
                        style="border-radius:4px 0px 0px 4px; flex-grow:3 !important;" />
                    <Button 
                        type="submit" 
                        :loading="buscar_is_loading"  
                        @click="obtener_participantes" 
                        icon="pi pi-search" 
                        style="border-radius:0px 4px 4px 0px;">
                    </Button>
                </div>
                <p v-if="!parametro_is_valid" class="p-text-danger p-text-center">{{validation_text}}</p>
            </form>
        </div>
    </div>
    <Message v-if="resultados_busqueda === 0 && busqueda_realizada" :closable="false" severity="warn">No se han encontrado registros</Message>
    <Message v-if="participante_agregado === 0" :closable="false" severity="error">El participante ya se encuentra en la lista</Message>
    <div v-if="Object.keys(resultados_busqueda).length > 0" class="p-mt-3">
        <span>RESULTADOS:</span>
        <div class="p-list-group">
            <div class="p-list-group-item p-d-flex p-jc-between p-ai-center">
                <span>{{resultados_busqueda.nombre}}</span>
                <Button @click="agregar_paricipante" class="p-button-sm" icon="pi pi-user-plus" label="AGREGAR"></Button>
            </div>
        </div>
    </div>
    <div class="p-col p-p-0 p-mt-3">
        <Button class="p-button-text p-button-secondary" icon="pi pi-angle-left" label="VOLVER" @click="salir_buscar_participante"></Button>
    </div>
</template>

<script>
export default {
    name : 'BuscarParticipante',
    data (){
        return {
            parametro_participante : '',
            parametro_is_valid : true,
            tipo_usuario : null,
            busqueda_realizada : false,
            estado_agregar : true,
            buscar_is_loading : false,
            resultados_busqueda : [],
        }
    },
    props : {
        participante_agregado : {
            type : Number,
            default : 2
        } ,
        curso_actual : {
            type : Number,
        },
    },
    methods : {
        agregar_paricipante(){
            this.$emit('AgregarParticipante', this.resultados_busqueda);
            this.parametro_participante = "";
        },
        async obtener_participantes(){
            this.parametro_is_valid = true;
            this.busqueda_realizada = false;
            if(this.parametro_participante.trim().length == 0){
                this.parametro_is_valid = false;
                return false;
            }
            this.busqueda_realizada = true;
            this.buscar_is_loading = true;
            this.$emit('ReiniciarAgregar');
            await this.axios.post("/Cursos/Participante", { parametro : this.parametro_participante, curso : this.curso_actual })
            .then(r => { this.resultados_busqueda = r.data; })
            .catch(()=>{})
            .then(()=>{ this.buscar_is_loading = false; })
        },
        salir_buscar_participante(){
            this.$emit('SalirBusqueda');
        },
    },
    computed : {
        sin_resultados : function(){
            return this.resultados_busqueda.length == 0 ? true : false;
        },
        placeholder_text : function(){
            let t = this.$store.state.tipo;
            return t == 3 ? 'Escriba correo, carnet, código o usuario' : 'Escriba correo o carnet';
        },
        validation_text : function(){
            let t = this.$store.state.tipo;
            return t == 3 ? 'Debe escribir el parametro de búsqueda' : 'Debe escribir el correo o carnet';
        },
    },
    mounted (){
        this.tipo_usuario = this.$store.state.tipo;
    },
    watch : {
        participante_agregado : function(){
            if(this.participante_agregado == 1){
                this.$emit('SalirBusqueda');
            }else if(this.participante_agregado == 0){
                this.resultados_busqueda = [];
            }
        },
    }
}
</script>
