<template>
    <div>
        <div class="p-d-flex p-flex-column p-ai-start p-px-3">
            <div class="p-col-12 p-py-0 p-px-1">
                <p style="font-size:14pt; font-weight:500; color:#424242;" class="p-mb-0 p-text-center">{{ruta.nombre}}</p>
                <p class="p-text-center p-mb-2"><small style="letter-spacing: 1px;" class="text-muted">{{ruta.facultad}}</small></p>
                <p style="font-size:11pt; text-align:justify; color:#424242; line-height:1.7;" class="p-mt-2 p-mb-1">{{ruta.descripcion}}</p>
                <div class="p-col-12 p-d-flex p-jc-evenly">
                    <small class="text-muted" title="Regional">
                        <i class="pi pi-map-marker"></i>
                        {{ruta.regional}}
                    </small>
                    <small class="text-muted">.</small>
                    <small class="text-muted" title="Modalidad">
                        <i class="pi pi-desktop"></i>
                        {{ruta.modalidad}}
                    </small>
                    <small class="text-muted">.</small>
                    <small class="text-muted" title="Inicia">
                        <i class="pi pi-calendar"></i>
                        {{ruta.fecha_inicio}}
                    </small>
                </div>
                <div class="p-col-12 p-pb-0" :class="{'p-d-none' : detalles.length == 0}">
                    <p style="font-size:13pt; font-weight:500; color:#424242;" class="p-mb-0 p-text-center">Ruta de aprendizaje</p>
                </div>
                <div class="p-col-12 p-p-0" :class="{'p-d-none' : detalles.length == 0}">
                    <Carousel :value="detalles" :numVisible="1" :numScroll="1" :responsiveOptions="responsiveOptions">
                        <template #item="slotProps">
                            <Card id="card-detalle-ruta" style="border-radius: 0 !important; border: 0 !important; box-shadow: none !important; padding:0 !important;">
                                <template #title style="margin: 0 !important;">
                                    <div class="">{{slotProps.data.nombre}}</div>
                                </template>
                                <template #content>
                                    <div class="">{{slotProps.data.descripcion}}</div>
                                </template>
                            </Card>
                        </template>
                    </Carousel>
                </div>
            </div>
            <Divider layout="horizontal"/>
            <div class="p-col-12 p-p-0 p-m-0 p-d-flex p-jc-center">
                <div class="p-col-5 p-p-0 p-d-flex p-flex-column p-jc-start p-ai-start">
                    <div class="p-d-flex p-ai-center p-jc-center">
                        <span style="font-size:12pt;">PRECIO:&nbsp;</span>
                        <span style="font-size:16pt;" class="p-my-0">{{$format.usd(ruta.precio)}}</span>
                    </div>
                    <small class="p-text-secondary">Última fecha de pago {{ruta.pago_final}}</small>
                </div>
                <div class="p-col-6 p-text-center">
                    <div class="p-fluid">
                        <Button v-if="ruta.inscrito < 0" class="p-button-ugb" @click="inscribir_ruta(ruta.codigo)" label="INSCRIBIRME"></Button>
                        <Button v-else class="p-button-ugb" disabled="disabled" label="INSCRITO"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    #card-detalle-ruta > .p-card-body {
        padding: 10px;
    }

    #card-detalle-ruta > .p-card-body > .p-card-title{
        margin:  0 !important;
        color:#424242 !important;
        font-size: 12pt;
        font-weight: 500;
    }
    #card-detalle-ruta > .p-card-body > .p-card-content{
        padding: 10px 0px;
        text-align: justify;
    }
    .p-carousel-indicators{
        padding: 5px !important;
    }
</style>

<script>
export default {
    name: 'DetalleRuta',
    props: {
        ruta : {},
    },
    data(){ 
        return{
            responsiveOptions: [
                {
                    breakpoint: '1024px',
                    numVisible: 3,
                    numScroll: 3
                },
                {
                    breakpoint: '600px',
                    numVisible: 2,
                    numScroll: 2
                },
                {
                    breakpoint: '480px',
                    numVisible: 1,
                    numScroll: 1
                }
            ]
        }
    },
    methods : {
        image(url){
            return "background-image:url('"+url+"')"
        },
        inscribir_ruta(codigo){
            this.$emit('inscribir_ruta',codigo);
        }
    },
    computed : {
        detalles : function(){
            return this.ruta.detalle;
        }
    },
    mounted (){
    },
}
</script>