<template>
  <Toast position="top-right" />
  <ConfirmDialog :breakpoints="{'1800px' : '35vw', '1350px' : '35vw', '960px': '50vw', '640px': '98vw'}" :style="{width: '25vw'}"></ConfirmDialog>

  <div class="p-shadow p-bg-white p-container" style="min-height:100%;">
    <div class="p-d-flex p-ai-center p-jc-between p-flex-wrap p-my-3 p-mx-2">
      <div class="p-d-flex p-ai-start">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-filter"></i></span>
          <MultiSelect 
            v-model="filtro_rutas" 
            :showToggleAll="false" 
            scrollHeight="300px" 
            :options="lista_filtros_rutas" 
            optionLabel="label" 
            optionGroupLabel="label" 
            optionGroupChildren="items" 
            style="width:200px;" 
            placeholder="Seleccione"/> 
          <!-- <InputText 
            v-model="filtro_nombre" 
            placeholder="Buscar"
            style="width:200px;"/> -->
        </div>
        <div>
          <Button 
            v-if="filtro_rutas.length > 0" 
            v-tooltip.bottom="'Quitar filtos'" 
            icon="pi pi-times" 
            label="" 
            @click="filtro_rutas = [];" 
            class="p-button-text p-button-danger p-mb-2" />
        </div>
      </div>
      <div>
        <SelectButton v-model="vista" optionValue="value" :options="opciones_vista" dataKey="value">
          <template #option="slotProps">
            <i :class="slotProps.option.icon"></i>
          </template>
        </SelectButton>
      </div>
    </div>
    <div v-if="loading.cursos_is_loading" class="p-fluid p-d-flex p-flex-column p-ai-center p-jc-center p-mb-4">
      <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
      <span class="text-muted p-mt-2">CARGANDO DATOS</span>
    </div>
    <div class="p-grid p-mt-2 p-d-flex p-flex-wrap p-jc-evenly p-ai-stretch p-card-list">
      <div v-for="item in filtro_ruta" v-bind:key="item" class='p-mb-3' :class="{ 'padre-lista p-col-12 h-200px' : (vista === 'lista') }">
        <ElementoListaCardRuta
          :item="item" 
          :vista="vista"
          @detalle_pago="detalle_pago_ruta"
          @mostrar_detalles="mostrar_detalle_rutas">
        </ElementoListaCardRuta>
      </div>

      <!-- <pre>{{ cursos }}</pre> -->
      <div v-for="item in filtro_curso" v-bind:key="item" class='p-mb-3' :class="{ 'padre-lista p-col-12 h-200px' : (vista === 'lista') }">
        <ElementoListaCard 
          :item="item" 
          :vista="vista"
          :lista_cuotas="lista_cuotas"
          @detalle_pago="detalle_pago"
          @mostrar_detalles="mostrar_detalles">
        </ElementoListaCard>
      </div>
    </div>
  </div>

  <!-- DIALOGO PARA DETALLES DE CURSO -->
  <Dialog 
    header="DETALLES DE CURSO" 
    :position="'top'" 
    v-model:visible="modal.modal_detalle_curso" 
    :breakpoints="breakPointsModal" 
    :style="{width: '33vw'}" 
    :draggable="false" 
    closeOnEscape="true" 
    :modal="true">
    <DetalleCurso 
      :parametros="conf"
      :precios_cursos="precios_cursos" 
      :precio_cuota="precio_pagar_curso"
      :cuotas="lista_cuotas"
      :precio_grupal="precio_grupal.filter(x => x.curso == curso_actual)"
      :detalle_curso="cursos.filter(x=>x.codigo == curso_actual)" 
      @PagarCurso="detalle_pago($event)" />
  </Dialog>
  <!-- AGREGAR PARTICIPANTES -->
  <Dialog 
      header="AGREGAR PARTICIPANTES" 
      :position="'top'" 
      v-model:visible="modal.modal_informacion" 
      :breakpoints="breakPointsModal" 
      :style="{width: '33vw'}" 
      :draggable="false" 
      closeOnEscape="true" 
      :modal="true">
      <InformacionParticipantes @CerrarModal="modal.modal_informacion = false;"></InformacionParticipantes>
  </Dialog>
  <!-- AGREGAR CUOTAS -->
  <Dialog 
      header="AGREGAR CUOTAS" 
      :position="'top'" 
      v-model:visible="modal.modal_agregar_cuotas" 
      :breakpoints="breakPointsModal" 
      :style="{width: '33vw'}" 
      :draggable="false" 
      closeOnEscape="true" 
      :modal="true">
      <AgregarCuota 
          @actualizar_cuotas="agregar_cuota_pago"
          @close_modal="modal.modal_agregar_cuotas=false;" 
          :cuotas_pago="cuotas_pago.filter(x=>x.curso==curso_actual)"
          :cuota_defecto="cuota_defecto_curso" 
          :precio_cuota="precio_pagar_curso" 
          :cuotas="lista_cuotas.filter(x=>x.curso == curso_actual)" 
          :codigo_participante="participante_actual" 
          :codigo_curso="curso_actual">
      </AgregarCuota>
  </Dialog>
  <!-- DATOS FACTURACIÓN -->
  <Dialog 
    header="DATOS DE FACTURACIÓN"
    closeOnEscape="true" 
    v-model:visible="modal.modal_datos_facturacion" 
    @hide="pago_anombre_de = false"
    :position="'top'" 
    :breakpoints="breakPointsModal" 
    :style="{width: '33vw'}" 
    :draggable="false" 
    :modal="true">
    <DatosFacturacion 
      @close_modal="close_modal" 
      :pago_ccf="pago_ccf" 
      :curso_actual="curso_actual"
      :ruta_actual="ruta_actual"
      :tipo_pago="tipo_pago"
    />
  </Dialog>
  <!--DIALOGO PARA PAGO DE CURSOS modal_detalle_pago -->
  <Dialog 
    :header="titulo_modal" 
    :position="'top'" 
    @hide=" buscar_participante = false; pago_ccf = false; " 
    v-model:visible="modal.modal_detalle_pago" 
    :breakpoints="breakPointsModal" 
    :style="{width: '33vw'}" 
    :draggable="false" 
    closeOnEscape="true" 
    :modal="true">
    <div class="p-px-2" v-if=" !buscar_participante ">
      <div class="p-mb-2">
        <div class="p-d-flex p-jc-center p-text-center p-p-3" style="background:#e9e9e9; color:#424242;">
          <span style="font-size:13pt; font-weight:500;">{{pago_detalle.descripcion}}</span>
        </div>
        <div class="p-d-flex p-jc-end">
          <span class="text-primary pointer" title="Ver detalles del curso" @click="mostrar_detalles(pago_detalle.codigo)">Detalles de curso</span>
          <span v-if="precio_grupal_curso.length > 0" class="p-ml-4 text-primary pointer" @click="$refs.op.toggle">*Precios especiales</span>
          <OverlayPanel ref="op" appendTo="body" :showCloseIcon="false" id="overlay_panel" style="width: 300px" :breakpoints="{'960px': '75vw'}">
            <PreciosPreferenciales :precios="precio_grupal_curso" ></PreciosPreferenciales>
          </OverlayPanel>
          <!--CUOTAS CURSOS-->
          <span v-if="curso_aplica_cuotas" class="p-ml-4 text-primary pointer" @click="$refs.cuotas.toggle">*Pago en cuotas</span>
          <OverlayPanel ref="cuotas" appendTo="body" :showCloseIcon="false" id="overlay_panel" style="width: 300px" :breakpoints="{'960px': '75vw'}">
            <DetalleCuotas 
              :parametros="conf" 
              :precio_cuota="precio_pagar_curso" 
              :cuotas="lista_cuotas" 
              :curso="curso_actual">
            </DetalleCuotas>
          </OverlayPanel>
        </div>
      </div>
      <div class="p-mb-2"><span style="font-weight:500;">PARTICIPANTES</span></div>
      <div class="p-list-group p-mb-1">
        <div class="p-list-group-item p-d-flex p-flex-column p-ai-start p-jc-between p-px-3" v-for="item in lista_participantes" :key="item">
          <div class="p-col-12 p-p-0 p-d-flex p-jc-between p-ai-center">
            <div class="p-d-flex p-jc-start p-ai-center">
              <i class="pi pi-trash p-text-danger pointer" title="Quitar" @click="borrar_participante(item.codigo)"></i>
              <div class="p-d-flex p-flex-column p-jc-start p-ai-start p-ml-2">
                <span style="font-size:11pt" class="p-text-uppercase">{{item.nombre}}</span>
                <small 
                  v-if="curso_aplica_cuotas" 
                  class="text-primary pointer" 
                  @click="modal.modal_agregar_cuotas=true; participante_actual = item.codigo">
                  AGREGAR CUOTAS
                </small>
              </div>
            </div>
            <div class="p-d-flex p-flex-column p-jc-start p-ai-start">
              <span class="" style="font-size:12pt; font-weight:500;">
                {{$format.usd(obtener_precio_participante(item.codigo))}}
              </span>
              <small v-if="curso_aplica_cuotas">
                {{cuotas_pago.filter(x => x.curso == curso_actual && x.participante == item.codigo).length}} CUOTA(S)
              </small>
            </div>
          </div>
        </div>
        <div v-if="lista_participantes.length == 0" class="p-list-group-item p-d-flex p-ai-center p-jc-between">
          <span class="text-muted">DEBE AGREGAR PARTICIPANTES A LA LISTA</span>
        </div>
      </div>
      <div class="p-col p-d-flex p-jc-end p-ai-center p-p-0 p-mt-1">
        <Button 
          label="AGREGAR PARTICIPANTES" 
          icon="pi pi-angle-down" 
          :iconPos="'right'" 
          @click="$refs.menu_ruta.toggle($event)" 
          aria-haspopup="true" 
          class="p-button-text p-button-secondary" />
        <Menu id="overlay_menu_ruta" ref="menu_ruta" :model="lista_opciones_agregar" :popup="true" />
      </div>
      <Divider class="p-mt-3"/>
      <div class="p-col p-p-0 p-d-flex p-jc-between p-ai-center">
        <div class="p-col-12 p-p-0 p-d-flex p-jc-between p-ai-center">
          <div class="p-d-flex p-flex-column">
            <h5 style="font-weight:500; font-size: 12pt;" class="p-my-0">TOTAL A PAGAR: {{ $format.usd(total_pagar) }}</h5>
            <!-- <div class="field-checkbox p-pt-1">
              <Checkbox id="solicitarccf_curso" :disabled="extranjero === true" :binary="true" name="solicitarccf" value="1" v-model="pago_ccf" />
              <label for="solicitarccf_curso" class="text-muted">&nbsp;Solicitar Crédito Fiscal</label>
            </div> -->
          </div>
          <Button 
            label="PAGO EN LINEA" 
            :loading="loading.pago_is_loading" 
            v-bind="{'disabled' : lista_participantes.length == 0}"
            style="width:150px;" 
            @click="pre_pagar_curso()" 
            class="p-button-ugb" />
        </div>
        
      </div>
    </div>
    <div class="p-px-2" v-else-if="buscar_participante">
      <BuscarParticipante 
        @AgregarParticipante="agregar_paricipante"  
        @ReiniciarAgregar="participante_agregado = 2;"
        @SalirBusqueda="buscar_participante = false; participante_agregado = 2;"
        :curso_actual="curso_actual"
        :participante_agregado="participante_agregado">
      </BuscarParticipante>
    </div>
  </Dialog>
  <!--DIALOGO PARA DETALLE RUTAS -->
  <Dialog 
    header="DETALLES DE RUTA" 
    :position="'top'" 
    v-model:visible="modal.modal_detalle_rutas" 
    :breakpoints="breakPointsModal" 
    :style="{width: '33vw'}" 
    :draggable="false" 
    closeOnEscape="true" 
    :modal="true">
    <DetalleRuta 
      @inscribir_ruta="detalle_pago_ruta" 
      :ruta="rutas.filter(x => x.codigo == ruta_actual)[0]">
    </DetalleRuta>
  </Dialog >
  <!--DIALOGO PARA PAGO DE RUTAS modal_detalle_rutas-->
  <Dialog 
    header="INSCRIPCIÓN"
    :position="'top'" 
    v-model:visible="modal.modal_pago_ruta" 
    :breakpoints="breakPointsModal" 
    :style="{width: '33vw'}" 
    :draggable="false" 
    @hide="pago_ccf = false"
    closeOnEscape="true" 
    :modal="true">
    <div class="p-px-2" >
      <div class="p-mb-2">
        <div class="p-d-flex p-jc-center p-text-center p-p-3" style="background:#e9e9e9; color:#424242;">
          <span style="font-size:13pt; font-weight:500;">{{pago_detalle_ruta.descripcion}}</span>
        </div>
        <div class="p-d-flex p-jc-end">
          <span class="text-primary pointer" title="Ver detalles de ruta" @click="mostrar_detalle_rutas(pago_detalle_ruta.codigo)">Detalles de ruta</span>
        </div>
      </div>
      <div class="p-mb-2"><span STYLE="font-weight:500;">PARTICIPANTES</span></div>
      <div class="p-list-group p-mb-10">
        <div class="p-list-group-item p-d-flex p-flex-column p-ai-start p-jc-between p-px-3" v-for="item in lista_participantes_rutas" :key="item">
          <div class="p-col-12 p-p-0 p-d-flex p-jc-between p-ai-center">
            <div class="p-d-flex p-jc-start p-ai-center">
              <div class="p-d-flex p-flex-column p-jc-start p-ai-start p-ml-2">
                <span style="font-size:11pt" class="p-text-uppercase">{{item.nombre}}</span>
              </div>
            </div>
            <div class="p-d-flex p-flex-column p-jc-start p-ai-start">
              <span class="" style="font-size:12pt; font-weight:500;">{{$format.usd(item.precio)}}</span>
            </div>
          </div>
        </div>
        <div v-if="lista_participantes_rutas.filter(x => x.ruta == ruta_actual).length == 0" class="p-list-group-item p-d-flex p-ai-center p-jc-between">
          <span class="text-muted">DEBE AGREGAR PARTICIPANTES A LA LISTA</span>
        </div>
      </div>
      <Divider class="p-mt-3"/>
      <div class="p-d-flex p-jc-between p-ai-center">
        <div class="p-d-flex p-flex-column">
            <h5 style="font-weight:500; font-size: 12pt;" class="p-my-0">
              TOTAL A PAGAR: 
              {{$format.usd(lista_participantes_rutas.filter(x => x.ruta == ruta_actual).reduce((x,y) => x + y.precio, 0))}}
            </h5>
            <!-- <div class="field-checkbox p-pt-1">
              <Checkbox id="solicitarccf_curso" :disabled="extranjero === true" :binary="true" name="solicitarccf" value="1" v-model="pago_ccf" />
              <label for="solicitarccf_curso" class="text-muted">&nbsp;Solicitar Crédito Fiscal</label>
            </div> -->
          </div>
        <div v-if="lista_participantes_rutas.filter(x => x.ruta == ruta_actual).reduce((x,y) => x + y.precio, 0) > 0">
          <Button 
            label="PAGO EN LINEA" 
            @click="pre_pagar_ruta()" 
            v-bind="{'disabled' : (lista_rutas.rutas.filter(x => x.codigo == ruta_actual)[0].inscrito > 0) }" 
            :loading="loading.pago_ruta_is_loading" 
            style="width:150px;"
            class="p-button-ugb" />
        </div>
        <div v-else>
          <Button 
            v-if="lista_rutas.rutas.filter(x => x.codigo == ruta_actual)[0].inscrito < 0" 
            class="p-button-ugb" 
            @click="pagar_ruta_free" 
            style="width:150px;" 
            v-bind="{'disabled' : lista_participantes_rutas.length == 0}" 
            :loading="loading.pago_ruta_is_loading" 
            label="INSCRIBIRME"></Button>
          <Button v-else class="p-button-ugb" disabled="disabled" style="width:150px;" label="INSCRIBIRME"></Button>
        </div>
      </div>
    </div>
  </Dialog>
  <!--DIALOGO PARA ACTUALIZACIÓN DE DATOS-->
  <Dialog 
    header="ACTUALIZACIÓN DE DATOS" 
    :position="'top'" 
    v-model:visible="modal.modal_actualizacion_datos" 
    :breakpoints="breakPointsModal" 
    :style="{width: '33vw'}" 
    :draggable="false" 
    closeOnEscape="true" 
    :modal="true">
      <ActualizacionDatos @closeUpdateDataModal=" this.modal.modal_actualizacion_datos = false " />
  </Dialog>
</template>

<style>
  .p-fieldset-content{
    padding: 10px 12px !important;
  }
  .tab-menu-active{
    color: white !important;
    background: #263680 !important;
  }
  .tab-menu{
    width:150px !important;
    color: #616161;
    font-size: 12pt;
    padding: 10px 14px;
    background: white;
    border-radius: 6px;
  }
  .tab-menu:hover{
    background: #f1f1f1;
  }
  .h-200px{
    height:200px !important;
  }
  .p-selectbutton .p-button{
    border-radius:3px !important;
    background: #fff;
    color: #000;
    border: 0;
  }
  .p-selectbutton .p-button:focus-visible{
    border: none !important;
  }
  .p-selectbutton .p-button:hover{
    background: #fff !important;
  }
  .p-selectbutton .p-highlight:hover{
    background: #d9dbdf !important;
  }
  .p-selectbutton .p-button:focus{
    box-shadow: none !important;
  }
  .p-selectbutton .p-highlight:focus{
    background: #d9dbdf !important;
  }
  .p-selectbutton .p-highlight{
    background: #d9dbdf !important;
  }
  .p-selectbutton .p-button > i.pi{
    color:#343A40;
  }

  .select_cuotas{
    width:250px !important;
    margin-top: 10px;
  }
  .image{
    margin:auto; 
    height:200px; 
    width:100%; 
  }
  .image-land{
    margin:auto; 
    height:200px; 
    width:300px; 
  }
  .p-datatable .p-datatable-thead > tr > th {
    border-bottom: none !important;
    border-top: 1px solid #dee2e6;
  }

  .p-imagel img{
    object-fit: cover !important;
    width: 298px !important; 
  }

  @media (max-width: 780px) {
    .p-selectbutton:not[role]{
      display: none !important;
    }
    .categoria{
      display:block !important;
    }
    .padre-lista{
      padding: 0 !important;
      height:505px !important;
      width:300px !important;
    }
    .lista{
      flex-direction: column !important;
      width:302px;
      height:505px;
      margin :0 !important;
      flex-wrap: nowrap !important;
    }
    .lista-imagen{
      width:300px !important;
      height:200px !important;
    }
    .lista-cuerpo{
      height:400px !important;
      padding:0 !important;
    }
    .lista-info{
      padding: 7px 14px !important;
    }
    .lista-nombre{
      height:45px !important;
    }
    .lista-descripcion{
      height:90px !important;
      line-height: 1.5 !important;
      font-size:10.5pt !important;
    }
    .lista-detalles{
      height:60px !important;
    }
    .lista-precio{
      padding:0 !important;
    }
    .lista-botones{
      flex-direction:column !important;
      justify-content:flex-start !important;
      align-items:start;
      padding:0px 14px 14px 14px !important;
    }
    .lista-boton-pago{
      order:0;
      width:100%;
      margin-bottom:.2rem;
      padding:7px 10.5px !important;
      font-size:1rem !important;
    }
    .lista-fecha-pago{
      order:1;
    }
  }
  @media (max-width: 1300px) and (min-width: 781px) {
    .categoria{
      display:none !important;
    }
  }
  @media (max-width: 709px) {
    .p-card-list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .p-card-item{ margin:0 !important; }
  }
  .p-elevation{
    transition: .2s;
  }
  .p-elevation:hover{
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 4.7px 3.3px rgba(0, 0, 0, 0.048),
        0 5.5px 6px rgba(0, 0, 0, 0.06),
        0 7.3px 8.9px rgba(0, 0, 0, 0.072);
    transition: .3s;
  }
  th{
    text-align:left !important;
  }
  .p-inline-message-icon{ 
    display: none !important; 
  }
</style>

<script>
  import DetalleCurso from '@/pages/Cursos/Components/DetalleCurso.vue'
  import PreciosPreferenciales from '@/pages/Cursos/Components/PreciosPreferenciales.vue'
  import InformacionParticipantes from '@/pages/Cursos/Components/InformacionParticipantes.vue'
  import BuscarParticipante from '@/pages/Cursos/Components/BuscarParticipante.vue'
  import DetalleCuotas from '@/pages/Cursos/Components/DetalleCuotas.vue'
  import AgregarCuota from '@/pages/Cursos/Components/AgregarCuota.vue'
  import ElementoListaCard from '@/pages/Cursos/Components/ElementoListaCard.vue'
  import DetalleRuta from '@/pages/Cursos/Components/DetalleRuta.vue'
  import ElementoListaCardRuta from '@/pages/Cursos/Components/ElementoListaCardRuta.vue'
  import ActualizacionDatos from '@/pages/Cursos/Components/ActualizacionDatos.vue'
  import DatosFacturacion from '@/pages/Cursos/Components/DatosFacturacion.vue'

  export default{
    components : {
      'PreciosPreferenciales' : PreciosPreferenciales,
      'DetalleCurso' : DetalleCurso,
      'InformacionParticipantes' : InformacionParticipantes,
      'BuscarParticipante' : BuscarParticipante,
      'DetalleCuotas' : DetalleCuotas,
      'AgregarCuota' : AgregarCuota,
      'ElementoListaCard' : ElementoListaCard,
      'DetalleRuta' : DetalleRuta,
      'ElementoListaCardRuta' : ElementoListaCardRuta,
      'ActualizacionDatos' : ActualizacionDatos,
      'DatosFacturacion' : DatosFacturacion
    },
    data (){
      return{
        pago_ccf : false,
        vista_rutas : 0,
        tipo_pago : 'curso',
        extranjero : false,
        conf : {
          arancel : 70,
          porcentaje : 15,
          aplica : true,
        },
        vista : 'cartas',
        opciones_vista: [
          { icon: 'pi pi-th-large', value: 'cartas' },
          { icon: 'pi pi-bars', value: 'lista' }
        ],
        breakPointsModal : {
          '1800px' : '35vw', 
          '1350px' : '60vw', 
          '960px': '70vw', 
          '680px': '90vw', 
          '550px': '100vw'
        },
        participante_actual : 0,
        curso_actual : 0,
        ruta_actual : 0,
        cuotas_pago : [],
        filtro : [],
        filtro_nombre : '',
        lista : [],
        filtro_rutas : [],
        lista_rutas : {
          rutas : [],
          detalle : []
        },
        lista_opciones_agregar : [
          {
            label: 'Buscar',
            icon: 'pi pi-search',
            command: () => this.buscar_participante=true 
          },
          {
            label: 'Información',
            icon: 'pi pi-info-circle',
            command: () => this.modal.modal_informacion = true
          }
        ],
        lista_cuotas : [],
        precios_cursos : [],
        precio_grupal : [],
        resultados_busqueda : [],
        loading : {
          searching_by_nit : false,
          guardar_is_loading : false,
          cursos_is_loading : false,
          pago_is_loading : false,
          pago_ruta_is_loading : false,
        },
        modal : {
          modal_detalle_curso : false,
          modal_detalle_pago : false,
          modal_informacion : false,
          modal_agregar_cuotas : false,
          modal_detalle_rutas : false,
          modal_pago_ruta : false,
          modal_actualizacion_datos : false,
          modal_datos_facturacion : false,
        },
        buscar_participante : false,
        pago_detalle : {
          codigo : 0,
          descripcion : '',
          cantidad : 0,
          precio : 0,
          precio_global : 0,
        },
        participantes : [],
        participantes_rutas : [],
        lista_tipo_doc : [],
        lista_departamentos : [],
        lista_municipios : [],
        participante_agregado : 2,
      }
    },
    methods : {
      close_modal(){
        this.modal.modal_datos_facturacion = false;
      },
      detalle_pago_ruta(codigo){
        this.tipo_pago = 'ruta';
        this.ruta_actual = codigo;
        this.modal.modal_detalle_rutas = false;
        this.modal.modal_pago_ruta = true;
        if(this.lista_participantes_rutas.filter(x => x.ruta == this.ruta_actual).length == 0){
          this.participantes_rutas.push({
            ruta : this.ruta_actual,
            codigo : this.$store.state.codigo_cliente,
            nombre : this.$store.state.nombre,
            precio : this.lista_rutas.rutas.filter(x => x.codigo == this.ruta_actual)[0].precio,
          })
        }
      },
      mostrar_detalle_rutas(codigo){
        this.ruta_actual = codigo;
        this.modal.modal_pago_ruta = false;
        this.modal.modal_detalle_rutas = true;
      },
      obtener_precio_participante(participante){
        var total = this.cuotas_pago.filter(x => x.curso == this.curso_actual && x.participante == participante).length * this.precio_pagar_curso;
        var codigos_cuota = [];
        this.cuotas_pago.forEach(x => { if(x.curso == this.curso_actual && x.participante == participante) codigos_cuota.push(x.cuota); })
        this.lista_cuotas.forEach(x => { if(codigos_cuota.includes(x.codigo) && x.recargo == 1) total += (this.precio_pagar_curso * (this.conf.porcentaje / 100)) })
        return total;
      },
      agregar_cuota_pago(participante, cuotas){
        while(this.cuotas_pago.filter(x => x.participante == participante && x.curso == this.curso_actual).length > 0){
          let i = this.cuotas_pago.findIndex(x => x.participante == participante && x.curso == this.curso_actual);
          this.cuotas_pago.splice(i, 1);
        }
        cuotas.forEach(x => {
          this.cuotas_pago.push({
            participante: participante,
            curso : this.curso_actual,
            cuota : x,
          })[0];
        });
      },
      agregar_paricipante(p){
        let pe = this.lista_participantes.findIndex(x => x.codigo == p.codigo);
        this.participante_agregado = 2;
        if(pe >= 0){
          this.$toast.add({severity:'error', summary: 'Aviso', detail:'El participante ya se encuentra en la lista', life: 3000});
          this.participante_agregado = 0;
        }else{
          this.participantes.push({
            curso : this.curso_actual, 
            codigo : p.codigo, 
            nombre : p.nombre
          });
          let d = [this.cuota_defecto_curso];
          this.agregar_cuota_pago(p.codigo, d);
          this.$toast.add({severity:'success', summary: 'Éxito', detail:'Se ha agregado el participante', life: 3000});
          this.participante_agregado = 1;
        }
        this.resultados_busqueda = [];
      },
      async obtener_rutas(){
        this.loading.cursos_is_loading = true;
        await this.axios.post("/Rutas/Rutas")
        .then(r => { 
            if(r.data == 0){
              this.lista_rutas.rutas = [];
              this.lista_rutas.detalle = [];
            }else{
              this.lista_rutas.rutas = r.data.rutas;
              this.lista_rutas.detalle = r.data.detalle;
            }
          })
        .catch(() => {})
        .then(() => { this.loading.cursos_is_loading = false; });
      },
      image(url){
        return "background-image:url('"+url+"')"
      },
      validar_actualizacion(){
        return new Promise( (resolve, reject) => 
        {
          this.axios.post("/Cursos/ValidarActualizacion")
          .then(r => { 
            resolve(r);
          })
          .catch(r => {
            reject(r);
          });
        });
      },
      async pre_pagar_curso(){
        // actualización de datos
        var actualizar = false;
        var data = {};
        await this.validar_actualizacion()
        .then( (r) => { 
          data = r.data;
        })
        .catch( () => {
          this.$toast.add({
            severity:'error', 
            summary: 'Aviso', 
            detail:'Ha ocurrido un problema, favor reintentar', 
            life: 2000
          });
        });

        actualizar = !data.contenido.actualizado;
          
        if(actualizar){
          this.modal.modal_actualizacion_datos = true;
          return false;
        }

        this.$confirm.require({
          message: 'Se realizará el pago. ¿Desea proceder?',
          header: 'Confirmación',
          acceptLabel: 'Pagar',
          rejectLabel: 'Salir',
          defaultFocus: 'accept',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.pagar_curso();
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });

        // this.modal.modal_datos_facturacion = true;
        // this.traer_datos_facturacion();
      },
      async pre_pagar_ruta(){
        // actualización de datos
        console.log("pre_pagar_ruta");
        var actualizar = false;
        var data = {};
        await this.validar_actualizacion()
        .then( (r) => { 
          data = r.data;
          console.log(r);
        })
        .catch( () => {
          this.$toast.add({
            severity:'error', 
            summary: 'Aviso', 
            detail:'Ha ocurrido un problema, favor reintentar', 
            life: 2000
          });
        });

        actualizar = !data.contenido.actualizado;
          
        if(actualizar){
          // console.log("requiere actualizar");
          this.modal.modal_actualizacion_datos = true;
          return false;
        }

        this.$confirm.require({
          message: 'Se realizará el pago. ¿Desea proceder?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Pagar',
          rejectLabel: 'Salir',
          defaultFocus: 'accept',
          accept: () => {
            this.pagar_ruta();
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        });

        // this.modal.modal_datos_facturacion = true;
        // this.traer_datos_facturacion();
      },
      async pagar_curso(){
        let lista = this.cuotas_pago.filter(x => x.curso == this.curso_actual);
        let detalle = [];
        let participantes = [];

        lista.forEach(x => {
          detalle.push({
            codigo_curso : x.curso,
            codigo_cliente : x.participante,
            codigo_cuota : x.cuota,
            precio : this.precio_pagar_curso,
            recargo : false,
          })[0];

          if(x.cuota != null){
            let i = this.lista_cuotas.findIndex(y => y.codigo == x.cuota);
            let r = this.lista_cuotas[i].recargo;
            if(r == 1){
              detalle.push({
                codigo_curso : x.curso,
                codigo_cliente : x.participante,
                codigo_cuota : x.cuota,
                precio : (this.precio_pagar_curso * (this.conf.porcentaje / 100)),
                recargo : true,
              })[0];
            }
          }
        });

        this.lista_participantes.forEach(x => {
          if(x.curso == this.curso_actual){
            participantes.push(
              {
                codigo : x.codigo,
                precio : this.precio_pagar_curso
              }
            )[0];
          }
        });

        let payload = { 
          precio_total : this.total_pagar, 
          codigo : this.curso_actual,
          detalle : detalle,
          clientes : participantes,
          // datos_facturacion : this.form_facturacion,
          tipo_pago : this.tipo_pago,
        }

        this.loading.pago_is_loading = true;

        await this.axios.post("/Cursos/Solicitud", payload)
        .then(r => { 
          // console.log(r);
            
          this.$toast.add({
            severity: r.data.errores ? 'error' : 'success', 
            summary: 'Aviso', 
            detail: r.data.errores ? r.data.mensaje : 'Solicitúd exitosa, será redirigido en un instante', 
            life: 2000
          });

          setTimeout( () => {
            var contenido = JSON.parse(r.data.contenido);
            window.location.href = process.env.VUE_APP_URLPOS + contenido.codigo_solicitud;
          }, 2000)
        })
        .catch(() => { 
          this.loading.pago_is_loading = false;
          this.$toast.add({
            severity:'error', 
            summary: 'Aviso', 
            detail:'Ha ocurrido un problema, intentelo nuevamente más tarde', 
            life: 2000
          }); 
        }).then( () => {
          this.loading.pago_is_loading = false;
        })
      },
      async pagar_ruta(){
        let detalle = this.participantes_rutas.filter(x => x.ruta == this.ruta_actual).map((x) => {
          return {
            codigo_curso : x.ruta,
            codigo_cliente : x.codigo,
            codigo_cuota : null,
            precio : x.precio,
            recargo : false,
          }
        })
        let clientes = this.participantes_rutas.filter(x => x.ruta == this.ruta_actual).map((x) => {
          return {
            codigo : x.codigo,
            precio : x.precio
          }
        })

        let payload = { 
          precio_total : this.total_pagar, 
          codigo : this.ruta_actual,
          detalle : detalle,
          clientes : clientes,
          // datos_facturacion : this.form_facturacion,
          tipo_pago : this.tipo_pago,
        }

        this.loading.pago_ruta_is_loading = true;

        await this.axios.post("/Cursos/Solicitud", payload)
        .then(r => { 
          this.$toast.add({
            severity: r.data.errores ? 'error' : 'success', 
            summary: 'Aviso', 
            detail: r.data.errores ? r.data.mensaje : 'Solicitúd exitosa, será redirigido en un instante', 
            life: 2000
          });

            setTimeout( () => {
              var contenido = JSON.parse(r.data.contenido);
              window.location.href = process.env.VUE_APP_URLPOS + contenido.codigo_solicitud;
            }, 2000)
        })
        .catch(() => { 
          this.loading.pago_is_loading = false;
          this.$toast.add({
            severity:'error', 
            summary: 'Aviso', 
            detail:'Ha ocurrido un problema, intentelo nuevamente más tarde', 
            life: 2000
          });
        }).then( () => {
          this.loading.pago_ruta_is_loading = false;
        })
      },
      async pagar_ruta_free(){
        this.$confirm.require({
          message: 'SE INSCRIBIRÁ A ESTA RUTA ¿DESEA PROCEDER?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel : 'Realizar',
          rejectLabel : 'Cancelar',
          blockScroll : false,
          rejectClass : 'p-button-danger',
          accept: async () => {

            let detalle = this.participantes_rutas.filter(x => x.ruta == this.ruta_actual).map((x) => {
              return {
                codigo_curso : x.ruta,
                codigo_cliente : x.codigo,
                codigo_cuota : null,
                precio : x.precio,
                recargo : false,
              }
            })

            let clientes = this.participantes_rutas.filter(x => x.ruta == this.ruta_actual).map((x) => {
              return {
                codigo : x.codigo,
                precio : x.precio
              }
            })

            let payload = { 
              precio_total : this.total_pagar, 
              codigo : this.ruta_actual,
              detalle : detalle,
              clientes : clientes,
              // datos_facturacion : this.form_facturacion,
              tipo_pago : this.tipo_pago,
            }

            this.loading.pago_ruta_is_loading = true;

            await this.axios.post("/Cursos/Solicitud", payload)
            .then(r => { 

              if(!r.data.errores){
                this.modal.modal_pago_ruta = false;
                this.lista_rutas.rutas.map(x => {
                  if(x.codigo == this.ruta_actual) x.inscrito = 1 
                })
              }
                
              this.$toast.add({
                severity: r.data.errores ? 'error' : 'success', 
                summary: 'Aviso', 
                detail: r.data.errores ? r.data.mensaje : 'Solicitúd exitosa', 
                life: 2000
              });
            })
            .catch(() => { 
              this.loading.pago_is_loading = false;
              this.$toast.add({
                severity:'error', 
                summary: 'Aviso', 
                detail:'Ha ocurrido un problema, intentelo nuevamente más tarde', 
                life: 2000
              }); 
            }).then( () => {
              this.loading.pago_ruta_is_loading = false;
            })
          }
        });
      },
      async obtener_tipo_doc(){
        await this.axios.get("/Registro/TipoDocumentos").then(r => { this.lista_tipo_doc = r.data })
      },
      borrar_participante(codigo){
        this.$confirm.require({
          message: 'SE ELIMINARÁ EL PARTICIPANTE ¿DESEA PROCEDER?',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel : 'Eliminar',
          rejectLabel : 'Cancelar',
          blockScroll : false,
          rejectClass : 'p-button-danger',
          accept: () => {
            let index = this.participantes.findIndex(x => x.codigo == codigo && x.curso == this.curso_actual);
            this.participantes.splice(index, 1);
          }
        });
      },
      detalle_pago(curso){
        this.tipo_pago = 'curso';
        this.curso_actual = curso;
        this.modal.modal_detalle_curso = false;
        this.modal.modal_detalle_pago = true;
        if(this.lista_participantes.length == 0){
          this.participantes.push({
            curso : this.curso_actual,
            codigo : this.$store.state.codigo_cliente,
            nombre : this.$store.state.nombre,
          })
        }
        let participante = this.$store.state.codigo_cliente;
        if(this.cuotas_pago.filter(x => x.curso == this.curso_actual && x.participante == participante).length > 0){
          let c = [];
          this.cuotas_pago.forEach(x => {if(x.curso == this.curso_actual && x.participante == participante) c.push(x.cuota)});
          this.agregar_cuota_pago(participante, c);
        }else{
          this.agregar_cuota_pago(participante, [this.cuota_defecto_curso]);
        }
        for (let i = 0; i < this.lista.length; i++) {
          let p = this.cursos[i];
          if(p.codigo == curso){
            this.pago_detalle.codigo = p.codigo;
            this.pago_detalle.descripcion = p.nombre;
            this.pago_detalle.cantidad = 1;
            this.pago_detalle.precio = p.precio_mostrar;
            this.pago_detalle.precio_global = p.precio_tachado;
          }
        }
      },
      validar_precio(curso){
        let tipo = this.$store.state.tipo;
        let r = this.precios_cursos.findIndex((e) => e.curso == curso && e.tipo == tipo)
        let p = r >= 0 ? true : false;
        return p;
      },
      precio_especial(curso){
        let tipo = this.$store.state.tipo;
        let r = this.precios_cursos.findIndex((e) => e.curso == curso && e.tipo == tipo)
        if(r == -1) return 0;
        return this.precios_cursos[r].precio;
      },
      mostrar_detalles(codigo){
        this.curso_actual = codigo;
        this.modal.modal_detalle_pago = false;
        this.modal.modal_detalle_curso = true;
      },
      async traer_cursos(){
        this.loading.cursos_is_loading = true;
        await this.axios.get("/Cursos")
        .then(r => { 
          this.lista = r.data.cursos;
          this.precios_cursos = r.data.precios;
          this.precio_grupal = r.data.grupal;
          this.lista_rutas.rutas = r.data.rutas;
          this.lista_rutas.detalle = r.data.detalle_rutas;
          this.lista.filter(x => x.aplica_cuotas == 1).forEach(y => {
            let i = 0;
            r.data.cuotas.forEach(x => {
              if(x.curso == y.codigo){
                i++;
                x.n = i;
                this.lista_cuotas.push(x)[0];
              }
            })
          })
        })
        .catch(() => {})
        .then(() => { this.loading.cursos_is_loading = false; });
      },
      rango_precio(pa, global){
        var data = this.precio_grupal_curso;
        var precio = global;
        var estado = 0;
        var minimo = data[0].desde;
        if(pa < minimo) return global;
        for (let i = 0; i < data.length; i++) {
          var pr = data[i];
          if(pr.limite){
            if(pa >= pr.desde && pa <= pr.hasta){
              estado = 1;
              precio = pr.precio;
            }
          }else{
            if(pa >= pr.desde){
              estado = 1;
              precio = pr.precio;
            }
          }
          if(estado == 1){
            return precio;
          }
        }
        return precio;
      }
    },
    computed : {
      pago_detalle_ruta : function(){
        let detalle = {
          codigo : this.ruta_actual,
          descripcion : this.lista_rutas.rutas.filter(x => x.codigo == this.ruta_actual)[0].nombre,
          precio : this.lista_rutas.rutas.filter(x => x.codigo == this.ruta_actual)[0].precio,
        };
        return detalle;
      },  
      rutas : function(){
        let def = [];
        if((this.lista_rutas.rutas.length == 0)) return def;
        let c = this.lista_rutas;
        let r = c.rutas;
        let d = c.detalle;
        r.forEach(x => {
          let detalle = [];
          d.forEach(y => {
            if(x.codigo == y.codrut){
              detalle.push(y)
            }
          })
          x.detalle = detalle;
        })
        return r
      },  
      lista_participantes : function(){
        return this.participantes.filter(x=>x.curso == this.curso_actual);
      },
      lista_participantes_rutas : function(){
        return this.participantes_rutas.filter(x=>x.ruta == this.ruta_actual);
      },
      curso_aplica_cuotas : function(){
        return this.lista_cuotas.findIndex(x=>x.curso == this.curso_actual) >= 0 ? true : false;
      },
      cursos : function(){
        let c = [];
        this.lista.forEach(x => 
        {
          let especial = this.precio_especial(x.codigo);
          let global = x.precio_global;
          let cuota = x.precio_cuota;

          if(x.codigo == 2034){
            console.log("codigo => ", x.codigo, "cuota => ", cuota, "Global => ", global, "especial => ", especial);
          }

          let precio1 = 0, precio2 = 0

          // si hay descuento y no hay cuota
          if(especial != 0 && cuota == 0) {
            console.log(x);
            precio1 = especial;
            precio2 = global;
            // si hay descuento y cuota
          }else if(especial != 0 && cuota != 0){
            /*
              precio1 = especial;
              precio2 = cuota;
            */

            precio1 = (especial / (global /cuota));
            precio2 = global;

            
          /*}else if(especial != 0 && cuota != 0){*/

          // si hay solo cuota
          }else if(especial == 0 && cuota != 0){
            precio1 = cuota;
          }else{
            precio1 = global;
          }
          x.precio_mostrar = precio1;
          x.precio_tachado = precio2;
          c.push(x)
        });
        return c
      },
      curso_cuotas : function(){
        return this.lista_cuotas.findIndex(x=>x.curso == this.curso_actual) >= 0 ? true : false;
      },
      cuota_defecto_curso : function(){
        return this.lista.filter(x => x.codigo == this.curso_actual)[0].codigo_cuota;
      },
      filtro_curso : function(){
        let reg = [], cat = [], f = [], codigos = [], res = [], data = this.cursos

        this.filtro.forEach(item => { if(item.value == 'reg') reg.push(item.label) });
        this.filtro.forEach(item => { if(item.value == 'cat') cat.push(item.label) });  

        if(cat.length == 0 && reg.length == 0) 
          return data;

        let apply_cat = cat.length == 0 ? 0 : 1;
        let apply_reg = reg.length == 0 ? 0 : 1;

        data.forEach( x => {  
          if(cat.includes(x.categoria)){
            let i = f.findIndex(y => y.codigo == x.codigo);
            if(i >= 0) 
              f[i].comp++;
            else 
              f.push({codigo : x.codigo, comp : 1});
          }
        });

        data.forEach( x => {  
          if(reg.includes(x.regional)){
            let i = f.findIndex(y => y.codigo == x.codigo);
            if(i >= 0) 
              f[i].comp++;
            else 
              f.push({codigo : x.codigo, comp : 1});
          }
        });

        f.forEach( x => { 
          if(x.comp == (apply_cat + apply_reg)) 
            codigos.push(x.codigo); 
        });

        data.forEach( x => { 
          if(codigos.includes(x.codigo)) 
            res.push(x) 
        });

        return res;
      },
      filtro_ruta : function(){
        let reg = [], fac = [], f = [];
        let data = this.lista_rutas.rutas;
        this.filtro_rutas.forEach(item => { if(item.value == 'reg') reg.push(item.label) });
        this.filtro_rutas.forEach(item => { if(item.value == 'fac') fac.push(item.label) });  
        if(fac.length == 0 && reg.length == 0) 
        {
          return data;
        }
        let apply_cat = fac.length == 0 ? 0 : 1;
        let apply_reg = reg.length == 0 ? 0 : 1;
        data.forEach(x => {  
          if(fac.includes(x.facultad)){
            let i = f.findIndex(y => y.codigo == x.codigo);
            if(i >= 0) f[i].comp++;
            else f.push({codigo : x.codigo, comp : 1});
          }
        });
        data.forEach(x => {  
          if(reg.includes(x.regional)){
            let i = f.findIndex(y => y.codigo == x.codigo);
            if(i >= 0) f[i].comp++;
            else f.push({codigo : x.codigo, comp : 1});
          }
        });
        let codigos = [], res = [];
        f.forEach(x => { if(x.comp == (apply_cat + apply_reg)) codigos.push(x.codigo); });
        data.forEach(x=>{ if(codigos.includes(x.codigo)) res.push(x) });
        return res;
      },
      total_pagar : function(){
        if(this.tipo_pago == 'curso'){
          var codigos_cuota = [];
          let total = this.cuotas_pago.filter(x => x.curso == this.curso_actual).length * this.precio_pagar_curso;

          this.cuotas_pago.forEach(x => 
          {
            if(x.curso == this.curso_actual) 
              codigos_cuota.push(x.cuota);
          })

          this.lista_cuotas.forEach(y => 
          { 
            if(codigos_cuota.includes(y.codigo) && y.recargo == 1) 
              total += (this.precio_pagar_curso * (this.conf.porcentaje / 100)) * codigos_cuota.filter(x => x == y.codigo).length;
          })
          return total;
        }
        else
        {
          let total = this.lista_participantes_rutas.filter(x => x.ruta == this.ruta_actual).reduce((x,y) => x + y.precio, 0);
          return total;
        }
      },
      precio_pagar_curso : function(){
        if(this.curso_actual == 0) return 0

        let p = this.cursos.filter(x => x.codigo == this.curso_actual)

        let c = this.lista_participantes.length;

        let x = this.precio_grupal_curso.filter(x=>(c>=x.desde&&c<=x.hasta)||(c>=x.desde&&x.limite==false));

        let precio = p[0].precio_mostrar;
        
        if(x.length == 0){
          return precio;
        }
        
        return x[0].precio;
      },
      no_cursos : function(){
        return (this.loading.cursos_is_loading || this.lista.length > 0) ? false : true;
      },
      no_rutas : function(){
        return (this.loading.cursos_is_loading || this.lista_rutas.rutas.length > 0) ? false : true;
      },
      lista_filtros : function(){
        let sedes = this.lista_sedes;
        let categorias = this.lista_categorias;
        let p = [
          {label : "Categorias", value : "Categorias", items : categorias },
          {label : "Sedes", value : "sedes", items : sedes }
        ];
        return p
      },
      lista_filtros_rutas : function(){
        let facultades = this.lista_facultades;
        let sedes = this.lista_sedes_rutas;
        let p = [
          {label : "Facultad", value : "Facultades", items : facultades },
          {label : "Sedes", value : "sedes", items : sedes }
        ];
        return p
      },
      lista_sedes : function(){
        let arr = [];
        for (let i = 0; i < this.lista.length; i++) {
          if( arr.findIndex(x => x.label == this.lista[i]['regional']) == -1 ) {
            arr.push({label : this.lista[i]['regional'], value : 'reg' });
          }
        }
        return arr;
      },
      lista_categorias : function(){
        let arr = [];
        for (let i = 0; i < this.lista.length; i++) {
          if( arr.findIndex(x => x.label == this.lista[i]['categoria']) == -1 ) {                        
            arr.push({label : this.lista[i]['categoria'], value : 'cat' });
          }
        }
        return arr;
      },
      lista_facultades : function(){
        let fac = [];
        this.lista_rutas.rutas.map(x => {
          if( !fac.includes(x.codfac) ){
            fac.push(x.codfac)
          }
        });
        let r = fac.map(x => {
          return {
            value : 'fac',
            label : this.lista_rutas.rutas.filter(y => y.codfac == x)[0].facultad
          }
        })
        return r;
      },
      lista_sedes_rutas : function(){
        let reg = [];
        this.lista_rutas.rutas.map(x => {
          if( !reg.includes(x.regional) ){
            reg.push(x.regional)
          }
        });
        let r = reg.map(x => {
          return {
            value : 'reg',
            label : this.lista_rutas.rutas.filter(y => y.regional == x)[0].regional
          }
        })
        return r;
      },
      precio_grupal_curso : function(){
        return this.precio_grupal.filter(x => x.curso == this.curso_actual);
      },
      titulo_modal : function(){
        return this.buscar_participante ? "BUSCAR PARTICIPANTES" : "DETALLE DE PAGO";
      },
    },
    mounted (){
      if(this.$store.state.is_logged == 0) 
        this.$router.push("/");

      this.traer_cursos();
      this.obtener_tipo_doc();
    },
  }
</script>