<template>
  <div v-if="!pago_ccf">
    <div class="p-col-12 p-p-0 p-d-flex p-flex-column p-jc-center p-ai-center p-mb-2">
      <div class="p-d-flex p-jc-start p-ai-center">
        <Button 
          label="A mi nombre" 
          @click=" pago_anombre_de = false "
          style="border-radius: 4px 0px 0px 4px; border-width: 1px 0px 1px 1px;" 
          class="p-button-primary"
          :class=" pago_anombre_de ? 'p-button-outlined' : '' " />
        <Button 
          label="A nombre de" 
          @click=" pago_anombre_de = true "
          style="border-radius: 0px 4px 4px 0px;" 
          class="p-button-primary"
          :class=" pago_anombre_de ? '' : 'p-button-outlined' " />
      </div>
    </div>
    <!-- no modifica cliente -->
    <div v-if="!pago_anombre_de">
      <div class="p-col p-px-0 p-pb-2 p-d-flex p-jc-start p-ai-center mb-3">
        <div style="width:150px !important;">
          <label class="">Nombre:</label>
        </div>
        <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
          <InputText 
            type="text" 
            v-model="form_facturacion.nombre" 
            disabled
            style="flex-grow: 2;"
            placeholder="Nombre de persona o comercio" />
        </div>
      </div>
      <div class="p-col p-pt-2 p-px-0 p-d-flex p-jc-start p-ai-center">
        <div style="width:150px !important;">
          <label class="">Documento:</label>
        </div>
        <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
          <div class="p-d-flex" style="flex-grow: 2;">
              <Dropdown 
                v-model="form_facturacion.tipo_documento" 
                :options="lista_tipo_doc" 
                disabled
                optionLabel="nombre" 
                optionValue="codigo"
                placeholder="Seleccione" />&nbsp;
              <InputText 
                type="text" 
                v-model="form_facturacion.documento" 
                disabled
                style="flex-grow: 2;"
                placeholder="No de documento" />
          </div>
        </div>
      </div>
      <div v-if="!form_facturacion.extranjero" class="p-col p-pt-2 p-px-0 p-d-flex p-jc-start p-ai-center">
        <div style="width:150px !important;">
          <label class="">Ubicación:</label>
        </div>
        <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
          <div class="p-d-flex" style="flex-grow: 2;">
            <InputText 
              type="text" 
              v-model="form_facturacion.departamento" 
              style="flex-grow:2;"
              disabled/>
              <InputText 
              type="text" 
              style="flex-grow:2;"
              v-model="form_facturacion.municipio" 
              disabled/>
          </div>
        </div>
      </div>
      <div v-if="!form_facturacion.extranjero" class="p-col p-pt-2 p-px-0 p-d-flex p-jc-start p-ai-center">
        <div style="width:150px !important;">
          <label class="">Dirección:</label>
        </div>
        <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
          <InputText 
            type="text" 
            v-model="form_facturacion.direccion" 
            disabled
            style="flex-grow: 2;"
            placeholder="Dirección de calle" />
        </div>
      </div>
      <div class="p-col p-pt-2 p-px-0 p-d-flex p-jc-start p-ai-center">
        <div style="width:150px !important;">
          <label class="">Correo:</label>
        </div>
        <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
          <InputText 
            type="text" 
            v-model="form_facturacion.correo" 
            disabled
            style="flex-grow: 2;"
            placeholder="Correo electrónico" />
        </div>
      </div>
    </div>
    <!-- para busqueda de clientes -->
    <div v-else>
      <div class="p-col p-px-0 p-pb-2 p-col-12 p-flex-column p-d-flex p-jc-start p-ai-stretch p-mb-3">
        <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
          <label class="p-mb-0 p-pb-0 text-muted" for="txt_nit" >Búsqueda de cliente</label>
          <div class="p-d-flex p-ai-start">
            <div class="p-d-flex p-flex-column">
              <Dropdown 
                v-model="form_buscar_cliente.tipo_documento"
                :options="lista_documentos" 
                optionLabel="nombre" 
                optionValue="codigo"
                placeholder="Tipo de documento" />
              <span class="text-danger">{{ form_buscar_cliente.error.tipo_documento }}</span>
            </div>
            <div class="p-d-flex p-flex-column p-mx-1" style=" flex-grow:2;">
              <InputText 
                type="text" 
                name="txt_nit"
                id="txt_nit"
                v-model="form_buscar_cliente.documento" 
                style="flex-grow: 2;"
                placeholder="Número de identificación del cliente" />
              <span class="text-danger">{{ form_buscar_cliente.error.documento }}</span>
            </div>
            <Button 
              class="p-button-primary" 
              @click="buscar_cliente"
              :loading="searching_by_nit" 
              label="Buscar" />
          </div>
        </div>
        <Message v-if="no_cliente" class="p-mt-3 p-mb-0 " :closable="false" severity="warn">
          No se han encontrado resultados, uno de nuestros agentes se pondrá en contacto con usted para dar continuidad al proceso.
        </Message>
      </div>
      <Fieldset legend="Datos de cliente">
        <div class="p-col p-px-0 p-d-flex p-jc-start p-ai-center mb-3">
          <div style="width:150px !important;">
            <label class="">Nombre:</label>
          </div>
          <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
            <InputText 
              type="text" 
              v-model="form_busqueda.nombre" 
              disabled
              style="flex-grow: 2;"
              placeholder="Nombre de persona o comercio" />
          </div>
        </div>
        <div class="p-col p-pt-2 p-px-0 p-d-flex p-jc-start p-ai-center">
          <div style="width:150px !important;">
            <label class="">Ubicación:</label>
          </div>
          <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
            <div class="p-d-flex" style="flex-grow: 2;">
              <InputText 
                type="text" 
                v-model="form_busqueda.departamento" 
                style="flex-grow:2;"
                placeholder="Departamento"
                disabled/>
                <InputText 
                type="text" 
                style="flex-grow:2;"
                placeholder="Municipio"
                v-model="form_busqueda.municipio" 
                disabled/>
            </div>
          </div>
        </div>
        
        <div class="p-col p-pt-2 p-px-0 p-d-flex p-jc-start p-ai-center">
          <div style="width:150px !important;">
            <label class="">Dirección:</label>
          </div>
          <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
            <InputText 
              type="text" 
              v-model="form_busqueda.direccion" 
              disabled
              style="flex-grow: 2;"
              placeholder="Dirección de calle" />
          </div>
        </div>
        <!-- <div class="p-col p-pt-2 p-px-0 p-d-flex p-jc-start p-ai-center">
          <div style="width:150px !important;">
            <label class="">Correo:</label>
          </div>
          <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
            <InputText 
              type="text" 
              v-model="form_busqueda.correo" 
              style="flex-grow: 2;"
              disabled="true"
              placeholder="Correo electrónico" />
            <small class="text-muted">A este correo se enviará el comprobante al ser generado.</small>
          </div>
        </div> -->
      </Fieldset>
    </div>
    <div class="p-mt-4">
      <Button label="REALIZAR PAGO" v-bind:disabled="codcli_select == 0 && pago_anombre_de" @click="confirmar_facturacion()" class="p-button-ugb" />&nbsp;
      <Button label="SALIR" @click="handle_close_modal()" class="p-button-secondary" />
    </div>
    <div class="p-mt-2">
      <span class="text-muted"><span style="font-weight: 500;">Nota: </span>El comprobante será enviado al correo: <i>{{ form_facturacion.correo }}.</i></span>
    </div>
  </div>
  <!-- para ccf -->
  <div v-else>
    <Message severity="info" class="p-mt-0 p-mb-2" :closable="false">
      En caso de solicitar crédito fiscal cómo comprobante usted deberá realizar la búsqueda del cliente a quien será dirigido el comprobante.
    </Message>
    <div class="p-col p-px-0 p-pb-2 p-d-flex p-jc-start p-ai-center p-mb-2">
      <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
        <label class="p-mb-0 p-pb-0 text-muted" for="txt_nit" >Búsqueda de cliente</label>
        <div class="p-d-flex p-ai-start">
          <InputText 
            type="text" 
            name="txt_nit"
            id="txt_nit"
            v-model="form_buscar_cliente.documento" 
            style="flex-grow: 2;"
            placeholder="Ingrese NRC" />&nbsp;
          <Button 
            class="p-button-primary" 
            @click="buscar_cliente"
            :loading="searching_by_nit" 
            label="Buscar" />
        </div>
        <span class="text-danger">{{ form_buscar_cliente.error.documento }}</span>
      </div>
    </div>

    <Message v-if="no_cliente" class="p-mt-0 p-mb-3 " :closable="false" severity="warn">
      No se han encontrado resultados, uno de nuestros agentes se pondrá en contacto con usted para dar continuidad al proceso.
    </Message>
    
    <Fieldset legend="Datos de cliente">
      <div class="p-col p-px-0 p-d-flex p-jc-start p-ai-center mb-3">
        <div style="width:150px !important;">
          <label class="">Nombre:</label>
        </div>
        <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
          <InputText 
            type="text" 
            v-model="form_busqueda.nombre" 
            disabled
            style="flex-grow: 2;"
            placeholder="Nombre de persona o comercio" />
        </div>
      </div>
      
      <div class="p-col p-pt-2 p-px-0 p-d-flex p-jc-start p-ai-center">
        <div style="width:150px !important;">
          <label class="">Ubicación:</label>
        </div>
        <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
          <div class="p-d-flex" style="flex-grow: 2;">
            <InputText 
              type="text" 
              v-model="form_busqueda.departamento" 
              style="flex-grow:2;"
              placeholder="Departamento"
              disabled/>
              <InputText 
              type="text" 
              style="flex-grow:2;"
              placeholder="Municipio"
              v-model="form_busqueda.municipio" 
              disabled/>
          </div>
        </div>
      </div>
      
      <div class="p-col p-pt-2 p-px-0 p-d-flex p-jc-start p-ai-center">
        <div style="width:150px !important;">
          <label class="">Dirección:</label>
        </div>
        <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
          <InputText 
            type="text" 
            v-model="form_busqueda.direccion" 
            disabled
            style="flex-grow: 2;"
            placeholder="Dirección de calle" />
        </div>
      </div>

      <!-- <div class="p-col p-pt-2 p-px-0 p-d-flex p-jc-start p-ai-center">
        <div style="width:150px !important;">
          <label class="">Correo:</label>
        </div>
        <div class="p-d-flex p-flex-column" style="flex-grow: 2;">
          <InputText 
            type="text" 
            v-model="form_busqueda.correo" 
            style="flex-grow: 2;"
            disabled="true"
            placeholder="Correo electrónico" />
          <small class="text-muted">A este correo se enviará el comprobante al ser generado.</small>
        </div>
      </div> -->
    </Fieldset>
  
    <div class="p-mt-4">
      <Button label="REALIZAR PAGO" v-bind:disabled="codcli_select==0" @click="confirmar_facturacion()" class="p-button-ugb" />&nbsp;
      <Button label="SALIR" @click="handle_close_modal()" class="p-button-secondary" />
    </div>
    <div class="p-mt-2">
      <span class="text-muted"><span style="font-weight: 500;">Nota: </span>El comprobante será enviado al correo: <i>{{ form_facturacion.correo }}.</i></span>
    </div>
  </div>
</template>

<script>
export default {
  name : 'DatosFacturacion',
  props  : {
    pago_ccf  : {
      type : Boolean,
      default : false
    },
    tipo_pago : {
      type : String
    },
    curso_actual : {
      type : Number
    },
    ruta_actual : {
      type : Number
    }
  },
  data(){
    return {
      form_facturacion : {
        nombre : '',
        extranjero : false,
        tipo_documento : 0,
        documento : '',
        departamento : '',
        municipio : '',
        direccion : '',
        correo : ''
      },
      form_busqueda : {
        nombre: '',
        municipio : '',
        departamento : '',
        direccion : '',
        correo : '',
      },
      no_cliente : false,
      codcli_select : 0,
      correo_envio : '',
      lista_documentos : [],
      documento_busqueda : '',
      txt_nit : '',
      form_buscar_cliente : {
        error : {
          documento : '',
          tipo_documento : ''
        },
        documento : '',
        tipo_documento : ''
      },
      pago_anombre_de : false,
      searching_by_nit : false,
    }
  },
  methods : {
    handle_close_modal : function(){
      this.$emit('close_modal', 'hola mundo');
    },
    async buscar_cliente(){

      if(!this.pago_ccf){
        this.form_buscar_cliente.error.tipo_documento = this.form_buscar_cliente.tipo_documento.length == 0 ? "Seleccione el tipo" : "";
      }
      this.form_buscar_cliente.error.documento = this.form_buscar_cliente.documento.length == 0 ? "Escriba el número de documento" : "";

      if(Object.values(this.form_buscar_cliente.error).join('').length > 0){
        return false;
      }

      this.searching_by_nit = true;
      let payload = {
        tipo_pago : this.tipo_pago[0],
        codigo : this.tipo_pago[0] == 'c' ? this.curso_actual : this.ruta_actual,
        tipo_documento : this.pago_ccf ? 'NRC' : this.lista_documentos.filter(x => x.codigo == this.form_buscar_cliente.tipo_documento)[0].nombre,
        documento : this.form_buscar_cliente.documento
      }
      await this.axios.post("/Cursos/ConsultaCliente", payload)
      .then(x => {
        console.log(x.data);
        // si no hay muestra mensaje, si hay no
        this.no_cliente = x.data.contenido == null;
        if(!this.no_cliente){
          this.codcli_select = x.data.contenido.codigo;
          this.form_busqueda.nombre = x.data.contenido.nombre;
          this.form_busqueda.municipio = x.data.contenido.municipio;
          this.form_busqueda.departamento = x.data.contenido.departamento;
          this.form_busqueda.direccion = x.data.contenido.direccion;
          this.form_busqueda.correo = x.data.contenido.correo;
        }
      })
      .catch( () => {
        this.searching_by_nit = false;
      })
      .then( () => {
        this.searching_by_nit = false;
      })
    },
    async confirmar_facturacion(){
      // datos de facturación
      if(this.tipo_pago == 'curso'){
          this.pagar_curso();
      }else{
          this.pagar_ruta();
      }
    },
    async traer_datos_facturacion(){
      await this.axios.post("/Cursos/DatosFacturacion")
      .then(r => { 
        this.form_facturacion.nombre = r.data.nombre;
        this.form_facturacion.direccion = r.data.direccion;
        this.form_facturacion.correo = r.data.correo;
        this.form_facturacion.documento = r.data.documento;
        this.form_facturacion.tipo_documento = r.data.tipodoc;
        this.form_facturacion.departamento = r.data.departamento;
        this.form_facturacion.municipio = r.data.municipio;
        this.form_facturacion.extranjero = r.data.extranjero;
        // if(!this.form_facturacion.extranjero){
        //   this.obtener_municipios();
        // }
      })
      .catch(r => {
        console.log(r);
      });
    },
    async obtener_departamentos(){
      await this.axios.get("/Registro").then(r => 
      { 
        this.lista_departamentos = r.data 
      })
    },
    async obtener_municipios(){
      this.municipio_is_loading = true
      await this.axios.get("/Registro/Municipios/"+this.form_facturacion.departamento)
      .then(r => { 
        this.lista_municipios = r.data;
      })
    },
    async traer_tipos_docs(){
      await this.axios.get("/Cursos/TipoDocBusqueda")
      .then(r => { 
        this.lista_documentos = r.data;
        this.form_buscar_cliente.tipo_documento = r.data[0].codigo;
      })
      .catch(r => {
        console.log(r);
      });
    },
  },
  computed : {
    doc_format_busqueda : function(){
      var lista = this.lista_documentos.length <= 0 ? "" : this.lista_documentos.filter(x => x.codigo == this.documento_busqueda);
      return lista.length > 0 ? lista[0].formato : "";
    },
  },
  mounted(){
    this.traer_tipos_docs();
    this.traer_datos_facturacion();
  }
}
</script>
