<template>
  <div>
    <div class="p-d-flex p-flex-column p-ai-start p-px-3">
      <div class="p-col-12 p-py-0 p-px-1">
        <p style="font-size:13pt; font-weight:500; color:#424242;" class="p-mb-0 p-text-center">{{ curso.nombre
          }}
        </p>
        <p class="p-text-center p-mb-2"><small style="letter-spacing: 1px;" class="text-muted">{{
          curso.categoria }}</small></p>
        <p style="font-size:11pt; text-align:justify; color:#424242; line-height:1.7;" class="p-mt-2 p-mb-1">
          {{ curso.descripcion }}</p>
        <div class="p-mt-2 p-mb-2" v-if="curso.instructor.trim().length > 0">
          <Chip class="p-text-capitalize p-px-3 p-mt-2" title="Instructor" :label="curso.instructor"
            icon="pi pi-user" />
        </div>
        <div class="p-d-flex p-jc-between p-ai-center p-mb-2 p-mt-1">
          <div class="p-col-7 p-p-0">
            <table class="p-m-0">
              <tr>
                <td class="text-muted" style="height:24px !important;"><i class="pi pi-map-marker"></i>&nbsp;Regional:</td>
                <td style="text-transform:capitalize !important;">{{ curso.regional }}</td>
              </tr>
              <tr>
                <td class="text-muted" style="height:24px !important; width:100px;"><i class="pi pi-desktop"></i>&nbsp;Modalidad:</td>
                <td>{{ curso.modalidad }}</td>
              </tr>
              <tr>
                <td class="text-muted" style="height:24px !important;"><i class="pi pi-calendar"></i>&nbsp;Comienza:</td>
                <td>{{ curso.fecha_inicio }}</td>
              </tr>
              <tr>
                <td class="text-muted" style="height:24px !important;"><i class="pi pi-calendar"></i>&nbsp;Termina:</td>
                <td>{{ curso.fecha_fin }}</td>
              </tr>
            </table>
          </div>
          <div class="p-col-5 p-p-0 p-d-flex p-flex-column p-ai-end" id="contenedor">
            <div class="p-col-5 p-p-0 p-d-flex p-jc-end p-ai-end">
              <span style="font-size:11pt; letter-spacing: 1px;">PRECIO:&nbsp;</span>
              <div class="p-d-flex p-flex-column p-jc-start">
                <small v-if="curso.precio_tachado != 0" class="text-muted"><del>{{ $format.usd(curso.precio_tachado) }}</del></small>
                <span style="font-size:16pt;" class="p-my-0">
                  {{ 
                    cuotas.findIndex(x => x.curso == curso.codigo) >= 0 ? 
                      $format.usd( cuotas.filter(x => x.curso == curso.codigo).length * curso.precio_mostrar) :
                      $format.usd(curso.precio_mostrar)
                  }}
                </span>
              </div>
            </div>
            <!--PRECIOS ESPECIALES-->
            <span v-if="precio_grupal.length > 0" class="text-primary pointer" @click="$refs.op.toggle">*Precios especiales</span>
            <OverlayPanel ref="op" appendTo="body" :showCloseIcon="false" id="overlay_panel" style="width: 300px"
              :breakpoints="{ '960px': '75vw' }">
              <PreciosPreferenciales :precios="precio_grupal"></PreciosPreferenciales>
            </OverlayPanel>
            <!--CUOTAS CURSOS-->
            <span v-if="cuotas.findIndex(x => x.curso == curso.codigo) >= 0" class="text-primary pointer"
              @click="$refs.cuotas.toggle">*Pago en cuotas</span>
            <OverlayPanel 
              ref="cuotas" 
              appendTo="body" 
              :showCloseIcon="false" 
              id="overlay_panel" 
              style="width: 300px"
              :breakpoints="{ '960px': '75vw' }">
              <DetalleCuotas 
                :parametros="parametros" 
                :precio_cuota="precio_cuota" 
                :cuotas="cuotas"
                :curso="curso.codigo">
              </DetalleCuotas>
            </OverlayPanel>
          </div>
        </div>
        <small class="p-text-secondary">Última fecha de pago {{ curso.ultima_fecha_pago }}</small>
      </div>
      <Divider layout="horizontal" />
      <div class="p-col-12 p-p-0 p-d-flex p-jc-center">
        <div class="p-col-6">
          <div class="p-fluid">
            <Button v-if="curso.inscrito < 0" class="p-button-ugb" @click="detalle_pago(curso.codigo)"
              label="INSCRIBIRME"></Button>
            <Button v-else class="p-button-ugb" icon="pi pi-check-circle" label="INSCRITO" disabled="disabled"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreciosPreferenciales from '@/pages/Cursos/Components/PreciosPreferenciales.vue'
import DetalleCuotas from '@/pages/Cursos/Components/DetalleCuotas.vue'
export default {
  name: 'DetalleCurso',
  props: {
    detalle_curso: {},
    precios_cursos: [],
    cuotas: [],
    parametros: {
      type: Object
    },
    precio_grupal: [],
    precio_cuota: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    detalle_pago(codigo) {
      this.$emit('PagarCurso', codigo);
    },
    modalidad_curso(mo) {
      return mo == "P" ? "Presencial" : mo == "V" ? "Virtual" : "Semipresencial";
    },
    validar_precio(curso) {
      let tipo = this.$store.state.tipo;
      let r = this.precios_cursos.findIndex((e) => e.curso == curso && e.tipo == tipo)
      let p = r >= 0 ? true : false;
      return p
    },
    precio_especial(curso) {
      let tipo = this.$store.state.tipo;
      let r = this.precios_cursos.findIndex((e) => e.curso == curso && e.tipo == tipo)
      return this.precios_cursos[r].precio;
    },
  },
  computed: {
    curso: function () {
      return this.detalle_curso[0]
    },
  },
  components: {
    'PreciosPreferenciales': PreciosPreferenciales,
    'DetalleCuotas': DetalleCuotas,
  },
}
</script>
